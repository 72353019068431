import React from 'react';
import Layout from '../components/Layout';
import About from '../components/About/About';

function AboutPage() {
  return (
    <Layout
      title="Dr. Gio Valiante | About"
      description="Learn more about Dr. Gio Valiante’s path to become a top performance psychologist."
    >
      <About />
    </Layout>
  );
}

export default AboutPage;
