import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import ImageComponent from '../Image/ImageComponent';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const About = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container className="h-100">
        <Row className="about-wrapper">
          <Col lg={4} sm={12}>
            <ImageComponent filename="aboutImage.png" alt="About Dr gio" />
          </Col>
          <Col lg={7} sm={12} className="offset-lg-1">
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <span className="about-dr-gio">About</span>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <h1>Dr. Gio</h1>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  Dr. Gio Valiante is regarded as one of the most successful performance coaches in
                  the world. He graduated with Bachelors and Masters degrees from the University of
                  Florida, and a Ph.D. from Emory University where he studied Social Cognitive
                  Theory under the world-renowned professor, Frank Pajares. Dr. Gio began applying
                  SCT to golfers, and the results were immediate and compelling: over 50
                  professional wins on the LPGA and PGA Tours.
                </p>
                <p className="about-wrapper__info-text">
                  Simply by changing their psychology, Dr. Gio was helping golfers change the
                  scoreboard. In football, he worked with the Florida Gators under Urban Meyer’s
                  tenure and helped individuals players aggregate their work into a National
                  Championship. He was invited by coach Sean McDermott to be the Head Performance
                  Coach of the Buffalo Bills as the Bills were mired in a 17 year playoff drought.
                  The results: 2 playoff appearances in the first 3 years.
                </p>
                <p className="about-wrapper__info-text">
                  Similarly, legendary investor Steve Cohen invited Dr. Gio to be the Head
                  Performance Coach for his hedge fund Point 72 Asset Management where he oversaw 50
                  portfolio managers investing $20 Billion of AUM. Many of his portfolio managers
                  had record settings years by using Dr. Gio to identify bias, critique their
                  theses, and remove both fear and personal limitations
                </p>
                <p className="about-wrapper__info-text">
                  The results are the same everywhere Dr. Gio runs his playbook. Unprecedented
                  success by committing to the Fearless process.
                </p>
              </div>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1200} distance="30px">
              <AniLink cover direction="up" to="/contact" bg="#3486fb">
                <span className="cta-btn">BOOK NOW</span>
              </AniLink>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
